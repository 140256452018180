import React from "react";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";

import { filteredBaunaHeiti } from "./atoms";
import { baunaheitiAtom } from "./atoms";

import { rdb } from "../firebase/realtimeConfig";
import { realtimeUpdateA, realtimeUpdateB } from "../firebase/realtimeSave";
import { onValue, ref } from "firebase/database";

export default function BaunaHeiti() {

const [dataLoaded, setDataLoaded] = useState(false);

const [filtered] = useAtom(filteredBaunaHeiti);

const [allNameList, setAllNameList] = useState([]);
const [kannskiList, setKannskiList] = useState([]);
const [ekkiList, setEkkiList] = useState([]);
const [commentList, setCommentList] = useState({});

// const [aglaKannskiList, setAglaKannskiList] = useState([]);
// const [aglaEkkiList, setAglaEkkiList] = useState([]);
// const [aglaCommentList, setAglaCommentList] = useState({});

const [commentCount, setCommentCount] = useState(null);
const [commentsLoaded, setCommentsLoaded] = useState(undefined);
const [checkedForComments, setCheckedForComments] = useState(false);
const [showKannski, setShowKannski] = useState(true);
const [showEkki, setShowEkki] = useState(true);
const [showRest, setShowRest] = useState(true);

const [firebaseData, setFirebaseData] = useState({});
// const [firebaseBaunir, setFirebaseBaunir] = useState({});
const [firebaseBaunaCount, setFirebaseBaunaCount] = useState(-1);
const [baunaHeiti, setBaunaHeiti] = useState([...baunaheitiAtom.init]);
const [makiKannski, setMakiKannski] = useState([]);
const [makiEkki, setMakiEkki] = useState([]);
const [user, setUser] = useState('Baldvin');

// Sækja lista frá FireBase:
useEffect(() => {
    const query = ref(rdb, "baunir");
    return onValue(query, (snapshot) => {
        const data = snapshot.val();
        setFirebaseData({...data})
        console.log('data = ', data)
      
        if (snapshot.exists()) {
            setFirebaseBaunaCount(Object.keys(data).length)
        }
    });
  }, []);

useEffect(() => {
    if (user === 'Agla') {
        loadFromLocalStorage("kannskiListAGLA");
        loadFromLocalStorage("ekkiListAGLA");
        loadFromLocalStorage("commentListAGLA");
    }    
    if (user === 'Baldvin') {
        loadFromLocalStorage("kannskiList");
        loadFromLocalStorage("ekkiList");
        loadFromLocalStorage("commentList");
    }    
}, []);
// Uppfæra MakaLista þegar skipt er um 'USER' með hnappinum.
useEffect(() => {
    const fireBaseKEYS = Object.keys(firebaseData);
    const fbKeyCount   = fireBaseKEYS.length;

    let tempEkkiListi_Oglu = [];
        let tempKannskiListi_Oglu = [];

        let tempEkkiListi_Baldvins = [];
        let tempKannskiListi_Baldvins = [];

        fireBaseKEYS.forEach((key) => {
            if (user === 'Baldvin') {
                const makaSvar = firebaseData[key].A;
                if (makaSvar) {
                    if (makaSvar === 1) { tempKannskiListi_Oglu.push(key); }
                    if (makaSvar === 2) { tempEkkiListi_Oglu.push(key); }
                }
            }
            if (user === 'Agla') {
                const makaSvar = firebaseData[key].B;
                if (firebaseData[key].B) {
                    if (makaSvar === 1) { tempKannskiListi_Baldvins.push(key); }
                    if (makaSvar === 2) { tempEkkiListi_Baldvins.push(key); }
                }
            }
        });

        console.log(`tempKannskiListi_Baldvins = `, tempKannskiListi_Baldvins)
        console.log(`tempEkkiListi_Baldvins = `, tempEkkiListi_Baldvins)
        console.log(`tempKannskiListi_Oglu = `, tempKannskiListi_Oglu)
        console.log(`tempEkkiListi_Oglu = `, tempEkkiListi_Oglu)

        if (user === 'Agla') {
            console.log(`setting "MakaLists for 'Öglu'...`)
            setMakiEkki([...tempEkkiListi_Baldvins])
            setMakiKannski([...tempKannskiListi_Baldvins])
        }
        if (user === 'Baldvin') {
            console.log(`setting "MakaLists for 'Baldvin'...`)
            setMakiEkki([...tempEkkiListi_Oglu])
            setMakiKannski([...tempKannskiListi_Oglu])
        }
}, [user]);
  
  useEffect(() => {
    console.log('...firebaseData = ', firebaseData);
    console.log('...firebaseData.length = ', Object.keys(firebaseData).length);
    Object.keys(firebaseData).forEach(key => {

        if (firebaseData[key].B && firebaseData[key].B === 1 && !kannskiList.includes(key)) {
            console.log(`...!!!:...!!!...!...firebaseData[${key}].B = ${firebaseData[key].B} er ekki local`);
            setKannskiList([...kannskiList, key])
            if (user === 'Baldvin') {
                saveToLocalStorage('kannskiList', [...kannskiList, key])
            }
            if (user === 'Agla') {
                saveToLocalStorage('kannskiListAGLA', [...kannskiList, key])
            }
        }
    })
}, [firebaseData]);

//Loada úr LocalStorage:
useEffect(() => {
    const savedUser = loadUSERfromLocalStorage();
    const currentUser = (savedUser) ? savedUser : 'Baldvin';
    if (currentUser && currentUser.length > 0 ) {
        console.log(`currentUser = ${currentUser}..and lists loaded from LocalStorage`)
        console.log(`..=> AppUserName = "user/setUser" has not been set with: "setUser(${savedUser})"!`)
    } else {
        console.log(`USER NOT FOUND in LocalStorage ("currentUser" returned: ${currentUser})`)
        console.log(`Using default username = 'Baldvin'`);
        if (currentUser === 'Baldvin') {
            console.log('Hér á að vera loadað listum fyrir "Baldvin"... :/')
            loadFromLocalStorage("kannskiList");
            loadFromLocalStorage("ekkiList");
            loadFromLocalStorage("commentList");
        }
        if (currentUser === 'Agla') {
            console.log('Hér á að vera loadað listum fyrir "Agla"... :/')
            loadFromLocalStorage("kannskiListAGLA");
            loadFromLocalStorage("ekkiListAGLA");
            loadFromLocalStorage("commentListAGLA"); 
        }
    }
}, []);

// Function sem Loadar öllum listum úr LocalStorage fyrir notanda.
const loadUsersLocalFiles = (forUser) => {
    console.clear();
    console.log(`Sæki lista fyrir "${forUser}": `)
    if (forUser === 'Baldvin') {
        loadFromLocalStorage("kannskiList");
        loadFromLocalStorage("ekkiList");
        loadFromLocalStorage("commentList");
    }
    else if (forUser === 'Agla') {
        loadFromLocalStorage("kannskiListAGLA");
        loadFromLocalStorage("ekkiListAGLA");
        loadFromLocalStorage("commentListAGLA"); 
    }
}

// useEffect sem ætti að kikka inn við "push on user-switch-button" efst í appi 
useEffect(() => {
    if (commentsLoaded && kannskiList.length > 0) {
        console.log(`Þetta ætti að ná í lista fyrir *${user}* úr LocalStorage`)
        loadUsersLocalFiles(user);
    }
}, [user]);
  
useEffect(() => {
    if (kannskiList && kannskiList.length > 0 && firebaseBaunaCount > 0) {
        kannskiList.forEach(kannski => {
            if (user === 'Baldvin') {
                if (firebaseData[kannski].B !== 1) {
                    console.log('------ fireBase KAnnskiBaldvin not up-to-date')
                }
            }
        })
    }
  }, [kannskiList]);

  useEffect(() => {
    setCommentCount(Object.keys(commentList).length)
    // console.log('commentList.length = ', Object.keys(commentList).length)
    // console.log('commentList: = ', commentList)
  }, [commentList]);

  useEffect(() => {
    if (commentCount > 0) {
        console.log('commentCount = ', commentCount)
        setCommentsLoaded(true)
    }
  }, [commentCount]);

  const saveToLocalStorage = (listName, listToSave) => {
    console.log(`"${listName}" saved to localStorage, ${listName}.length = ${listName.length}`)
    localStorage.setItem(listName, JSON.stringify(listToSave));
  }

  const saveUSERtoLocalStorage = (username) => {
    console.log(`username = "${username}" saved to localStorage`)
    localStorage.setItem("username", JSON.stringify(username));
  }
  const loadUSERfromLocalStorage = () => {
    const loadedUser = localStorage.getItem("username");
    console.log(`"username" = ${loadedUser} loaded from localStorage`)
    // setUser(loadedUser)
    return loadedUser;
  }

  const loadFromLocalStorage = async (listName) => {
    
    const listLoaded = await JSON.parse(localStorage.getItem(listName));
        
    switch (listName) {
        case "kannskiList":
            // setKannskiList((listLoaded) => [...listLoaded])
            setKannskiList([...listLoaded])
            console.log(`B...."kannskiList" loaded from localStorage: ` )
            console.log(`B...."kannskiList" = `, [...kannskiList] )
            console.log(`B....kannskiList.length = ${kannskiList.length}` )
            break;
        case "ekkiList":
            // setEkkiList((listLoaded) => [...listLoaded])
            setEkkiList([...listLoaded])
            console.log(`B...."ekkiList" loaded from localStorage: ` )
            console.log(`B...."ekkiList" = `, [...ekkiList] )
            console.log(`B....ekkiList.length = ${ekkiList.length}` )
            break;
        case "commentList":
            // setCommentList(listLoaded => ({ ...listLoaded }));
            setCommentList({...listLoaded});
            setCheckedForComments(true)
            console.log(`B...."commentList" loaded from localStorage: ` )
            console.log(`B...."commentList" = `, { ...listLoaded } )
            console.log(`B....commentList.length = ${Object.keys(listLoaded).length}` )
            
            break;
        case "kannskiListAGLA":
            setKannskiList([...listLoaded])
            console.log(`A:::: "kannskiListAGLA" loaded from localStorage: ` )
            console.log(`A:::: "kannskiListAGLA" = `, [...listLoaded] )
            console.log(`A:::: kannskiListAGLA.length = ${Object.keys(listLoaded).length}` )
            
            break;
        case "ekkiListAGLA":
            setEkkiList([...listLoaded])
            console.log(`A:::: "${listName}" loaded from localStorage: ` )
            console.log(`A:::: "${listName}" = `, [...listLoaded] )
            console.log(`A:::: ${listName}.length = ${listLoaded.length}` )
            break;
        case "commentListAGLA":
            setCommentList({...listLoaded})
            console.log(`A:::: commentListAGLA" loaded from localStorage: ` )
            console.log(`A:::: "commentListAGLA" = `, {...listLoaded} )
            console.log(`A:::: commentListAGLA.length = ${listLoaded.length}` )
            
            setCheckedForComments(true)
            break;
        default:
            break;
    }
    return listLoaded;
  }

  const addToKannski = (e) => {
    if (e.target.checked) {
        document.querySelectorAll(`#${e.target.id}`)[1].checked=false;
                
        if ( !kannskiList.includes(e.target.id) ) {
            setKannskiList([...kannskiList, e.target.id])
            if (user === 'Baldvin') {
                realtimeUpdateB(e.target.id, 1)
                saveToLocalStorage('kannskiList', [...kannskiList, e.target.id])
            }
            if (user === 'Agla') {
                realtimeUpdateA(e.target.id, 1)
                saveToLocalStorage('kannskiListAGLA', [...kannskiList, e.target.id])
            }
        }
        if ( ekkiList.includes(e.target.id) ) {
            const removed = ekkiList.filter(nafn => nafn !== e.target.id)
            setEkkiList((removed) => [...removed])
            if (user === 'Baldvin') {
                saveToLocalStorage('ekkiList', removed)
            }
            else if (user === 'Agla') {
                saveToLocalStorage('ekkiListAGLA', removed)
            }
            
        }
    }
    if (!e.target.checked) {
        const removed = kannskiList.filter(nafn => nafn !== e.target.id)
        setKannskiList([...removed])
        
        if (user === 'Baldvin') {
            realtimeUpdateB(e.target.id, 0)
            saveToLocalStorage('kannskiList', removed)
        }
        if (user === 'Agla') {
            realtimeUpdateA(e.target.id, 0)
            saveToLocalStorage('kannskiListAGLA', removed)
        }
    }
    if (ekkiList.includes(e.target.id)) {
        const removed = ekkiList.filter(nafn => nafn !== e.target.id)
        setEkkiList([...removed])
        if (user === 'Baldvin') {
            saveToLocalStorage('ekkiList', [...removed])
        }
        if (user === 'Agla') {
            saveToLocalStorage('ekkiListAGLA', [...removed])
        }
    }
  }

  const addToEkki = (e) => {
    if (e.target.checked) {
        document.querySelectorAll(`#${e.target.id}`)[0].checked=false;

        if ( !ekkiList.includes(e.target.id) ) {
            setEkkiList([...ekkiList, e.target.id])
            
            if (user === 'Baldvin') {
                realtimeUpdateB(e.target.id, 2)
                saveToLocalStorage('ekkiList', [...ekkiList, e.target.id])
            }
            if (user === 'Agla') {
                realtimeUpdateA(e.target.id, 2)
                saveToLocalStorage('ekkiListAGLA', [...ekkiList, e.target.id])
            }
        }
    }
    if (!e.target.checked) {
        if ( ekkiList.includes(e.target.id) ) {
            const removed = ekkiList.filter(nafn => nafn !== e.target.id)
            setEkkiList([...removed])
            
            if (user === 'Baldvin') {
                realtimeUpdateB(e.target.id, 0)
                saveToLocalStorage('ekkiList', [...removed])
            }
            if (user === 'Agla') {
                realtimeUpdateA(e.target.id, 0)
                saveToLocalStorage('ekkiListAGLA', [...removed])
            }
        }
    }
    if (kannskiList.includes(e.target.id)) {
        const removed = kannskiList.filter(nafn => nafn !== e.target.id)
        setKannskiList([...removed])
        if (user === 'Baldvin') {
            saveToLocalStorage('kannskiList', [...removed])
        }
        if (user === 'Agla') {
            saveToLocalStorage('kannskiListAGLA', [...removed])
        }
    }
  }

  function debounce(cb, delay = 250) {
    let timeout
  
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        cb(...args)
      }, delay)
    }
  }

  // Immitate set action in useState
  const commentHandler = debounce(e => {
    let commentToAdd = e.target.value;
    let nameCommentedOn = e.target.id;
    let updatedComment = {}
    updatedComment[`${nameCommentedOn}`] = `${commentToAdd}`;
    // let updatedCommentList = {...commentList, [`${nameCommentedOn}`]: `${commentToAdd}`}
    let updatedCommentList = {...commentList, ...updatedComment}

    setCommentList({...updatedCommentList})
    if (user === 'Baldvin') {
        saveToLocalStorage('commentList', updatedCommentList)
    }
    if (user === 'Agla') {
        saveToLocalStorage('commentListAGLA', updatedCommentList)
    }
    console.log('The comment is: "', commentToAdd, '" for name: ', nameCommentedOn);
    // console.log('The comment is: "', e.target.value, '" for name: ', e.target.id);
  }, 500)

  const kannskiClickHandler = () => {
    console.log('--- kannskiClickHandler ---')
    console.log('--- kannskiList.length = ', kannskiList.length)
    console.log('--- kannskiList = ', kannskiList)
    setShowKannski(!showKannski)
  }
  const ekkiClickHandler = () => {
    console.log('--- ekki--Clicked ---')
    console.log('--- ekkiList.length = ', ekkiList.length)
    console.log('--- ekkiList = ', ekkiList)
    setShowEkki(!showEkki)
  }
  const restClickHandler = () => {
    console.log('Eftir--Clicked')
    console.log('"restList".length = ', baunaHeiti.length - kannskiList.length - ekkiList.length)
    console.log('showRest = ', !showRest)
    setShowRest(!showRest)
  }

  const RenderStats = ({filtered,kannskiList,ekkiList}) => {
    return (
        <div className="grid stats">
            <div className="filt-and-total">
                <div className="stats-item stats-filtered">
                    <span className="stats-label filtered-label" id="filtered-label">#</span> 
                    <div className="stats-filtered-numbers">
                        <span className="filtered stats-percentage filtered-percentage">{`${Math.round(100*(filtered.length / 2365),0)} %`}</span> 
                        <span className="filtered filtered-number" id="filtered-number">{filtered.length} </span>
                    </div>
                </div>
                <div className="stats-item stats-total">
                    <span className="stats-label all-label" id="total-label">Samtals</span>
                    <span className="stats-number statnumber" id="total-number">2365</span>
                </div>
            </div>
            
            <div className="stats-item stats-maybe" onClick={kannskiClickHandler} id={showKannski ? "show-list" : "hide-list"}>
                <span className="stats-percentage maybe-percentage">{`${Math.round(100*(kannskiList.length / 2365),0)} %`}</span> 
                <span className="stats-label maybe-label">Kannski: </span> 
                <span className="stats-number maybe-number">{kannskiList.length}</span>  
            </div>
            <div className="stats-item stats-not" onClick={ekkiClickHandler} id={showEkki ? "show-list" : "hide-list"}>
                <span className="stats-percentage eliminated-percentage">{`${Math.round(100*(ekkiList.length / 2365),0)} %`}</span> 
                <span className="stats-label eliminated-label">Ekki:</span> 
                <span className="stats-number eliminated-number">{ekkiList.length}</span>  
            </div>

            <div className="stats-item stats-rest" onClick={restClickHandler} id={showRest ? "show-list" : "hide-list"}>
                <span className="stats-label rest-label">Eftir:</span>
                <div className="rest-stats-numbers">
                    <span className="stats-percentage rest-percentage">{`${Math.round(100*((2365 - kannskiList.length - ekkiList.length) / 2365),0)} %`}</span>
                    <span className="stats-number restnumber">{2365 - kannskiList.length - ekkiList.length}</span>
                </div>
            </div>
        </div>
    );
  }

  const RenderListRow = ({n}) => {
    return (
        <tr key={n} className="nafnalina" 
            id={
                makiKannski.includes(n) 
                ? (kannskiList.includes(n)) ? "sammalaKannskiLina" : "makiKannski" 
                : makiEkki.includes(n)
                    ? ekkiList.includes(n) ? "sammalaEkkiLina" :"makiEkki"
                    : ""
            }
        >
            <td className={`stafur ${n[0]}`} id={makiKannski.includes(n) ? "makiKannski" : ""}>{n}</td>
            <td className="kannski" >              
                <label className="checkbox-wrap" id="maybe-box">.
                    <input 
                        type="checkbox"
                        id={n} name="fatchance" 
                        value="kannski"
                        className="checkbox maybe-box"
                        onClick={addToKannski}
                        checked={ kannskiList.includes(n) ? true : false}
                        onChange={addToKannski}
                    />
                    <span className="checkmark"
                        id={
                            (kannskiList.includes(n) && makiKannski.includes(n))
                            ? "sammalaKannski"
                            : "kannskiBox"
                        }
                    >
                        {(kannskiList.includes(n) && makiKannski.includes(n)) ? ":)" : ""}
                    </span>
                </label>
            </td>
            <td className="onEkkiList" 
                id={
                    (ekkiList.includes(n) && makiEkki.includes(n))
                    ? "sammalaEkki"
                    : "ekkiBox"
                }
            >
                <label className="checkbox-wrap" id="not-box">.
                    <input 
                        type="checkbox" 
                        id={n} name="fatchance" 
                        value="no" 
                        className="checkbox not-box"
                        checked={ ekkiList.includes(n) ? true : false}
                        onClick={addToEkki}
                        onChange={addToEkki}
                    />
                    <span className="checkmark"></span>
                </label>
            </td>
            <td className="comment">
                {
                (commentsLoaded && commentList[n]) 
                ? <input 
                    type="text" className="commentbox"
                    id={n} key={n}
                    value={commentList[n]}
                    placeholder="loaded"
                    onChange={(e) => commentHandler(e)}
                 /> 
                 : <input 
                        type="text" className="commentbox"
                        placeholder=".."
                        id={n} key={n}
                        onChange={(e) => commentHandler(e)}
                />}
            </td>
        </tr>
    );
  }
  const RenderKannskiLIST = () => {
    return (
        <tbody>
            {filtered.map((n) => (
                kannskiList.includes(n) && (
                    <RenderListRow n={n} key={n}/>
                )
            ))}
        </tbody>
    );
  }
  const RenderEkkiLIST = () => {
    return (
        <tbody>
            {filtered.map(
                (n) => ekkiList.includes(n) && <RenderListRow n={n} key={n}/>
            )}
        </tbody>
    );
  }
  const RenderRestLIST = () => {
    return (
        <tbody>
            {filtered.map( (n) => !kannskiList.includes(n) && !ekkiList.includes(n) && <RenderListRow n={n} key={n}/> )}
        </tbody>
    );
  }

  useEffect(() => {
    console.log('......USER = ', user)
  }, [user]);

  const UserButton = () => {
    const changeUser = () => {
        if (user === 'Baldvin') {
            setUser('Agla')
            saveUSERtoLocalStorage('Agla')
            console.log('USER *was* = "Baldvin" and now SWITCHED TO => "Agla"')
        }
        else if (user === 'Agla') {
            setUser('Baldvin')
            saveUSERtoLocalStorage('Baldvin')
            console.log('USER *was* = "Agla" and now SWITCHED TO => "Baldvin"')
        }
    }

    return (
        <div className="userinfo-wrap">
            <div className="UserInfo" onClick={changeUser} id={user==='Baldvin' ? "BALDVIN" : "AGLA"}>
                {user}
            </div>
        </div>
    );
  }

  return (
    <div className="container">
        <RenderStats filtered={filtered} kannskiList={kannskiList} ekkiList={ekkiList}/>
        <UserButton />   
        {checkedForComments && 
        <table>
            <thead>
                <tr className="headerlina">
                    <th id="name-header">Baunanafn</th>
                    <th className="checkbox-header" id="maybe-checkbox-header">Kannski</th>
                    <th className="checkbox-header" id="not-checkbox-header">Ekki!</th>
                    <th id="comment-header">ATHUGASEMD</th>
                </tr>
            </thead>
            
            { (showKannski) ? <RenderKannskiLIST/> : <></> }
            { (showEkki)    ? <RenderEkkiLIST   /> : <></> }
            { (showRest)    ? <RenderRestLIST   /> : <></> }
        </table>
        }
    </div>
  );
}
