import { ref, set } from "firebase/database";
import { rdb } from "./realtimeConfig";

// const db = getDatabase();
export function realtimeSave (baunaNafn, geit, status, comment) {
  
  set(ref(rdb, 'baunir/' + baunaNafn), {
    geit: status,
    comment: comment
  });
}

export function realtimeSaveA (baunaNafn, status) { 
    set(ref(rdb, 'baunir/' + baunaNafn), {
      A: status,
    });
}
export function realtimeSaveB (baunaNafn, status) { 
    set(ref(rdb, 'baunir/' + baunaNafn), {
      B: status,
    });
}

export function realtimeUpdateA (baunaNafn, status) { 
    set(ref(rdb, 'baunir/' + baunaNafn + '/A/'), status);
}
export function realtimeUpdateB (baunaNafn, status) { 
    set(ref(rdb, 'baunir/' + baunaNafn + '/B/'), status);
}


export function realtimeSetMaybe (baunaNafn, decider) { 
    set(ref(rdb, 'baunir/' + baunaNafn + '/' + decider), 1);
}
export function realtimeSetEliminated(baunaNafn, decider) { 
    set(ref(rdb, 'baunir/' + baunaNafn + '/' + decider), 2);
}

export default realtimeSave;