import { atom } from "jotai";
import BaunaNafnaListi from "./BaunaNafnaListi";

export const baunaheitiAtom = atom(BaunaNafnaListi);
export const filterAtom = atom("");

export const filteredBaunaHeiti = atom(
    (get) => get(filterAtom).includes('*') 
            ? get(baunaheitiAtom).filter(n => new RegExp('^' + get(filterAtom).replace(/\*/g, '.*') + '$', "i").test(n)) 
            : get(baunaheitiAtom).filter((n) => n.toLowerCase().includes(get(filterAtom).toLowerCase()) ) 
);
