import React from "react";
import { useAtom } from "jotai";

import { filterAtom } from "./atoms";
import { filteredBaunaHeiti } from "./atoms";

export default function FilterInput() {
  const [filter, filterSet] = useAtom(filterAtom);
  const [filtered] = useAtom(filteredBaunaHeiti);

  return (
    <div className="search-box">
        <div className="search-row">
            <div className="search-input-label"> Baunanafn: </div>
            <input name="search-input" value={filter} className="search-input" onChange={(evt) => filterSet(evt.target.value)} />
        </div>
    </div>
  );
}
